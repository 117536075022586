import React from 'react'
import { Col } from './Layout/Grid'
import Img from './Img'
import styled, {createGlobalStyle} from 'styled-components'
import Slider from 'react-slick'
import { media } from '../components/Utilities/Media'

const TeamCss = createGlobalStyle`
    .team-slider {
        margin-top: 60px;
        .slick-slide {
            padding: 0 15px;
        }
        .slick-dots {
            bottom: -50px;
            li button:before {
                font-size: 15px;
            }
            li.slick-active button:before {
                color: #00a3e9;
            }
        }
    }
`

const TeamImg = styled(Img)`
    display: block;
    margin: 0 auto;
    height: 140px;
    width: 140px;
    border-radius: 50%;
`

const TeamCol = styled(Col)`
    background-color: white;
    border-radius: 20px;
    padding: 28px 15px;
    @media ${media.lg} {
        padding: 28px 30px;
    }
`

const TeamDesc = styled.div`
    background: linear-gradient(to right, #00b4db, #0083b0); 
    color: white;
    text-align: center;
    border-radius: 35px;
    padding: 10px 15px;
    span {
        display: block;
        &:first-of-type {
            font-size: 18px;
        }
        &:last-of-type {
            font-size: 13px;
            margin-top: 5px;
        }
    }
` 

const TeamText = styled.div`
    margin-top: 18px;
    padding:0 10px;
`

const TeamWords = styled.div`
    margin-top: 20px;
    text-align: center;
    line-height: 1.5;
`

const TeamData = [
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic" : require("../images/people-1.jpg")
    },
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic" : require("../images/people-2.jpg")
    },
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic" : require("../images/people-2.jpg")
    },
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic": require("../images/people-4.jpg")
    },
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic" : require("../images/people-5.jpg")
    },
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic" : require("../images/people-3.jpg")
    },
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic" : require("../images/people-1.jpg")
    },
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic": require("../images/people-2.jpg")
    },
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic" : require("../images/people-4.jpg")
    },
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic" : require("../images/people-2.jpg")
    },
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic" : require("../images/people-1.jpg")
    },
    {
        "name": "Lorem Ipsum",
        "position": "ipsum lorem",
        "pic": require("../images/people-5.jpg")
    }
]

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    speed: 1000,
    responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        }
    ]
}

const Team = () => {
    return (
        <>
        <TeamCss />
        <Slider {...settings} className={'team-slider'}>
            {
                TeamData.map(team => (
                    <TeamCol>
                        <TeamImg src={team.pic} />
                        <TeamText>
                            <TeamDesc>
                                <span>{ team.name }</span>
                                <span>{ team.position }</span>
                            </TeamDesc>
                            <TeamWords>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </TeamWords>
                        </TeamText>
                    </TeamCol>
                ))    
            }
        </Slider>
        </>
    )
}

export default Team
