import React from 'react'
import Layout from '../components/Layout'
import { Row, Col } from '../components/Layout/Grid'
import styled from 'styled-components'
import { colorBlack, fontSizeHeading2, fontSizeHeading3, colorLightBlue, colorLightGray } from '../components/Variables/Variables'
import { CustomWrapper, Wrapper } from '../components/Wrapper'
import Btn from '../components/Button/Btn'

/* temp image */
import App1 from '../images/approach-1.jpg';
import App2 from '../images/approach-2.jpg';
import App3 from '../images/approach-3.jpg';
import Img from '../components/Img'
import Team from '../components/Team'
import { media } from '../components/Utilities/Media'
import { Container } from '../components/Layout/Container'
import {Controller, Scene} from 'react-scrollmagic'

const AboutStyled = styled.div`
   .about {
       &__kv {
           height: calc(100vh - 104px);
           display: flex;
           align-items: center;
           color: white;
           background: url(${require('../images/about.jpg')}) center no-repeat;
           background-size: cover;
       }
       &__kvText {
         width: 50%;
         h1 {
             font-size: 60px;
         }
         p {
             margin-top: 25px;
             line-height: 1.75;
         }
         a {
             margin-top: 30px;
             display: inline-block;
         }
       }

       &__twocol {
           max-width: 1080px;
           margin: 50px auto 0;
           img {
               width: 100%;
           }
           > div {
               flex-basis: 50%;
           }
           &:last-of-type {
               flex-direction: row-reverse;
               margin-top: 105px;
           }
       }
       &__colText {
           padding:0 40px;
           opacity: 0;
           transition: .8s all ease-in-out 1s;
           transform: translateY(20px);
           h3 {
                font-size: 40px;
                margin-bottom: 20px;
            }
            p {
                line-height: 1.75;
            }
       }
       &__colImg {
           flex-basis: 45%;
       }

       &__sectionTitle {
        h2 {
                font-size: 28px;
                margin-bottom: 20px;
                text-align: center;
        }
         p {
            max-width: 520px;
            margin: 0 auto;
            line-height: 1.7;
            text-align: center;
         }
       }

       &__team {
           margin-top: 100px;
           background-color: #ECEFF1;
           padding: 80px 0 80px;
       }
   }

   .animated-section {
       .about__colText {
           opacity: 1;
           transform: translateY(0);
       }
   }
`

const About = () => {
    return(
        <>
            <Layout>
                <AboutStyled>
                    <Container cname="about__kv">
                    <Wrapper>
                        <div className="about__kvText">
                            <h1>Who we are</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                                ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>
                            <a><Btn primary>Contact Us</Btn></a>
                        </div>
                        </Wrapper>
                    </Container>
                            <Container>
                            <Controller>
                                <Scene classToggle="animated-section" triggerHook={1} offset={100} reverse={false}>
                                <Row cname="about__twocol" vcenter hlign={'space-between'}>
                                    <Col cname="about__colText">
                                        <h3>Some Text</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                                    </Col>
                                    <Col cname="about__colImg"><img src={App1}/></Col>
                                </Row>
                                </Scene>
                             </Controller>
                             <Controller>
                                <Scene classToggle="animated-section" triggerHook={1} offset={100} reverse={false}>
                                <Row cname="about__twocol" vcenter>
                                    <Col cname="about__colText">
                                        <h3>Some Text</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                                    </Col>
                                    <Col cname="about__colImg"><img src={App2}/></Col>
                                </Row>
                                </Scene>
                            </Controller>
                            </Container>
                    <Container cname="about__team">
                        <div className="about__sectionTitle">
                            <h2>Meet Our Team</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <Team />
                    </Container>
                </AboutStyled>
            </Layout>
       </>
    )
}

export default About
